// Generated by Framer (10753fe)

import { addFonts, cx, CycleVariantState, getFonts, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import { Icon as Feather } from "https://framerusercontent.com/modules/f0DboytQenYh21kfme7W/zb1zVBMZJKgPMiedOi0y/Feather.js";
const FeatherFonts = getFonts(Feather);

const cycleOrder = ["yNiMlqfnD"];

const variantClassNames = {yNiMlqfnD: "framer-v-5b880f"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "yNiMlqfnD", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "yNiMlqfnD", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-MQxiD", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-5b880f", className)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"yNiMlqfnD"} ref={ref} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-d959a975-0422-481c-93b2-01f9fa36c2a3, rgb(215, 215, 215)) /* {\"name\":\"gray 2\"} */", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4, ...style}} transition={transition}><motion.div className={"framer-1jrrelr-container"} layoutDependency={layoutDependency} layoutId={"Bfhk096eK-container"} transition={transition}><Feather color={"rgb(0, 0, 0)"} height={"100%"} iconSearch={"Home"} iconSelection={"home"} id={"Bfhk096eK"} layoutId={"Bfhk096eK"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-MQxiD [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-MQxiD .framer-13luhd2 { display: block; }", ".framer-MQxiD .framer-5b880f { height: 32px; position: relative; width: 32px; }", ".framer-MQxiD .framer-1jrrelr-container { flex: none; height: 22px; left: calc(50.00000000000002% - 22px / 2); position: absolute; top: calc(50.00000000000002% - 22px / 2); width: 22px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FramerjJmpL2bCX: React.ComponentType<Props> = withCSS(Component, css, "framer-MQxiD") as typeof Component;
export default FramerjJmpL2bCX;

FramerjJmpL2bCX.displayName = "icon";

FramerjJmpL2bCX.defaultProps = {height: 32, width: 32};

addFonts(FramerjJmpL2bCX, [...FeatherFonts])